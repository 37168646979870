import { apiCallback } from 'api';
import { trackPersonalisationHeader } from 'api/utils/personalisation-header';

const SORT_CATEGORY_RANKING = 'categoryRanking';
const SORT_WEIGHTED_FAVOURITES = 'weightedFavourites';

const definition = {
  defaultVersion: 1,
  endpoint: 'products',
  query: {
    availableDateTimeRange: '${dateTime}',
    branchId: '${branchId}',
    categoryId: '${categoryId}',
    customersFavouritesOnly: '${favourites}',
    excludeLinesFromTrolley: '${excludeTrolleyLines}',
    filters: '${filters}',
    productCollectionId: '${collectionId}',
    promotionflag: '${promotionflag}',
    searchTerm: '${searchTerm}',
    size: '${size}',
    slotType: '${slotType}',
    sortBy: '${sortBy}',
    start: '${start}',
    trolleyId: '${trolleyId}',
    view: 'SUMMARY',
    showRecommendations: '${showRecommendations}',
    excludeLinesWithConflicts: '${excludeLinesWithConflicts}',
    promotionTypes: '${promotionTypes}',
  },
  headers: { experience: 'forgotten-favourites' },
  service: 'products',
  verbs: ['get'],
};

export default {
  SORT_CATEGORY_RANKING,
  SORT_WEIGHTED_FAVOURITES,
  get: apiCallback(definition, 'get', trackPersonalisationHeader),
};
